<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-btn dense icon class="mr-1">
          <v-icon style="cursor: pointer" large @click="goContact">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <v-col class="mt-2 p-0" justify="center">
          <span class="font-weight-bold" style="font-size: 1.2em">{{ form.name }}</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense class="mb-0 pb-0 mt-1">
        <v-col cols="12" md="auto" sm="12">
          <v-btn :block="$vuetify.breakpoint.xs" outlined color="#F15858" dense>
            <v-icon dark left>
              {{ icons.mdiFileDocument }}
            </v-icon>
            {{ $t('import') }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="auto" sm="12">
          <v-btn :block="$vuetify.breakpoint.xs" outlined color="#F15858" dense>
            <v-icon dark left>
              {{ icons.mdiFileDocument }}
            </v-icon>
            {{ $t('Export') }}
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="auto">
          <v-btn block color="primary" dense @click="$refs.dialogAddResponse.open(null, tags)">
            <v-icon dark left>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ $t('Add Response') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      item-key="id"
      :headers="customHeaders"
      :items="customDataResponse"
      :loading="tableLoading"
      show-select
      class="text-no-wrap"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
        'items-per-page-options': [5, 10, 25, 50, 75, 100],
      }"
    >
      <template #[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dense icon class="ml-n4" @click="editResponse(item.response_id)">
              <v-icon color="#F15858">
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
            <v-btn dense icon @click="deleteResponse(item.id, item.response_id)">
              <v-icon color="#F15858">
                {{ icons.mdiTrashCanOutline }}
              </v-icon>
            </v-btn>
          </template>
        </v-menu>
      </template>

      <template #[`item.user`]="{ item }">
        <div class="d-flex align-center" style="white-space: initial; max-width: 20vh">
          <v-avatar size="40">
            <v-img :src="getProfPic(item.user.profile_picture, item)" :lazy-src="defaultProfPic" height="40" width="40">
            </v-img>
          </v-avatar>
          <div class="d-flex flex-column ms-3">
            <span
              style="text-align: left"
              class="d-block font-weight-semibold text-truncate text--primary phone-number"
              >{{ item.user.name }}</span
            >
            <span style="text-align: left" class="text-xs">
              {{ item.user.phoneNumber }}
            </span>
          </div>
        </div>
      </template>
    </v-data-table>
    <confirm ref="confirm"></confirm>
    <dialogAddResponse
      ref="dialogAddResponse"
      :form-data="form"
      :response-data="customDataResponse"
      @fetch-survey="fetchSurvey"
      @fetch-response="fetchResponse"
    ></dialogAddResponse>
  </v-card>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { mdiChevronLeft, mdiFileDocument, mdiPencilOutline, mdiPlus, mdiTrashCanOutline } from '@mdi/js'
import confirm from '../component/Confirm.vue'
import dialogAddResponse from '../component/DialogAddResponse.vue'

export default {
  name: 'FormDetail',
  components: {
    dialogAddResponse,
    confirm,
  },
  data() {
    return {
      icons: {
        mdiChevronLeft,
        mdiFileDocument,
        mdiPlus,
        mdiTrashCanOutline,
        mdiPencilOutline,
      },
      customHeaders: [
        {
          text: 'Name',
          value: 'user',
          showData: true,
        },
      ],
      tags: [],
      customDataResponse: [],
      form: '',
      tableLoading: false,
    }
  },
  computed: {
    defaultProfPic() {
      return `${process.env.VUE_APP_WA_API_URL}ppic/@whatsapp.net/2414214242414/a.jpg`
    },
  },
  async mounted() {
    this.fetchSurvey()
    this.fetchResponse()
  },
  methods: {
    editResponse(responseId) {
      const response = this.customDataResponse.find(response => response.response_id === responseId)
      this.$refs.dialogAddResponse.open(response, this.tags)
    },
    deleteResponse(contactId, responseId) {
      this.$refs.confirm
        .open(this.$t('delete'), `${this.$t('Delete response ?')}`, { color: 'red' }, this.$t('delete'))
        .then(async agree => {
          if (agree) {
            try {
              const filteredContacts = this.customDataResponse
                .filter(contact => contact.id !== contactId && contact.response_id === responseId)
                .map(contact => contact.id)
              if (!filteredContacts.length) {
                // delete tag
                for (let i = 0; i < this.tags.length; i++) {
                  const tag = this.tags[i]
                  const id = this.$Md5Hash(`${tag.id}-${contactId}`)
                  await clientPocketBase.collection('crm_contact_tags').delete(id)

                  const getContact = await clientPocketBase.collection('crm_contacts').getOne(contactId)
                  const findTag = getContact.tags.indexOf(tag.id)
                  if (findTag > -1) {
                    getContact.tags.splice(findTag, 1)
                  }

                  await clientPocketBase.collection('crm_contacts').update(contactId, {
                    tags: getContact.tags,
                  })
                }

                await clientPocketBase.collection('crm_form_response').delete(responseId)
              } else {
                await clientPocketBase.collection('crm_form_response').update(responseId, {
                  contacts: filteredContacts,
                })
              }
              this.customDataResponse = []
              this.fetchResponse()
            } catch (error) {
              console.log(error)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    goContact() {
      this.$router.push({ path: '/contacts' })
    },
    getProfPic(img, item) {
      console.log(img, '<<viraa')
      if (img) return img
    },
    async fetchSurvey() {
      try {
        this.tableLoading = true
        const { formId } = this.$route.params
        const foundData = await clientPocketBase.collection('crm_contact_form').getOne(formId)
        this.form = foundData
        foundData.survey_json.forEach(element => {
          this.customHeaders.push({
            text: element.title ? element.title : element.name,
            value: element.name,
            showData: element.showData,
          })
        })
        this.customHeaders.push({
          text: 'Action',
          value: 'action',
          showData: true,
        })
        this.tags = foundData
        this.tableLoading = false
      } catch (error) {
        console.log(error)
      }
    },
    async fetchResponse() {
      try {
        this.tableLoading = true
        const { formId } = this.$route.params

        // console.log('sayang formId', formId);
        const responses = await clientPocketBase.collection('crm_form_response').getFullList(200, {
          filter: `responses.form = '${formId}'`,
          expand: 'contacts,responses',
          sort: 'updated',
          $autoCancel: false,
        })
        this.customDataResponse = []
        await responses.forEach(async response => {
          // console.log('sayang response', response.expand.responses.filter(el => el.form === formId));
          const contact = response.expand.contacts
          const allPhoneNumber = await clientPocketBase.collection('crm_contact_phones').getFullList(200, {
            expand: 'contact_id',
            filter: `contact_id.id = '${contact.id}'`,
            $autoCancel: false,
          })
          const phoneNumbers = allPhoneNumber.map(el => el.phone_number)
          const dataResponse = {
            id: contact.id,
            response_id: response.id,
            user: {
              name: contact.editedName || contact.name,

              // phoneNumber: contact.phone.phone_number.join(','),
              phoneNumber: phoneNumbers.join(','),
              profile_picture: contact.editedAvatar || contact.avatar,
            },
            updated: response.updated,
            response_data: response.expand.responses.filter(el => el.form === formId),
          }

          for (let i = 0; i < dataResponse.response_data.length; i++) {
            const response_data = dataResponse.response_data[i].response

            for (const property in response_data) {
              const matchQuestion = this.customHeaders.find(header => header.value === property)

              // console.log('sayang mydata', property, matchQuestion, this.customHeaders)

              // console.log("sayang match", matchQuestion);

              if (matchQuestion) {
                if (matchQuestion.showData) {
                  if (response.is_singleton) {
                    dataResponse[property] = response_data[property]
                  } else if (Array.isArray(dataResponse[property])) {
                    dataResponse[property] = [...dataResponse[property], response_data[property]]
                  } else {
                    dataResponse[property] = [response_data[property]]
                  }
                }
              }
            }
          }

          this.customDataResponse.push(dataResponse)
        })

        this.customDataResponse.sort((a, b) => b.updated - a.updated)
        this.tableLoading = false
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>
.heigth-dialog {
  max-height: 400px;
  overflow-y: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }

  .phone-number {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}

.img-avatar {
  width: 141.89px;
  height: 130px;
  margin-left: 109.15px;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-radius: 20px;
}
.colorline-chat {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.vac-avatar-frame {
  height: 51px;
  width: 52px;
  min-height: 53px;
  min-width: 52px;
  margin-right: 15px;
  margin-left: 5%;
  border-radius: 50%;
  margin-bottom: -1px;
}

.name-contact {
  color: #111b21;
  font-weight: 400;
  font-size: 16px;
}
</style>
